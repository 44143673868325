$color1: #ef7521;

body {
	background: #f2f2f2;
}

body, a, a:hover {
	color: #555;
}

.content {
	display: flex;
	justify-content: flex-end;
}

.no-padding {
	padding: 0!important;
}

.primary-bg,
.table thead th {
	background: $color1;
	color: #fff;
}

.primary-border {
	border-color: $color1!important;
}

.view-container {
	width: 80%;
	min-width: calc(100% - 250px);
	padding: 6rem 2rem;
	background: #f2f2f2;
}

ul li a.active {
	border-left: 5px solid $color1;
	padding-left: calc(1rem - 5px)!important;
	color: $color1;
	font-weight: 500;
}

.view-container .container {
	max-width: 1200px;
}

.view-container .table {
	margin-bottom: 3rem;
}

.view-container .card {
	padding: 2rem 1rem;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.view-title {
	font-size: 1.75rem;
	margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
	.view-container {
		min-width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.view-title {
		font-size: 1.5rem;
	}

	.view-container {
		padding: 6rem 1rem;
	}
}